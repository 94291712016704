import React from 'react';
import Layout from '../components/Layout/Layout';
import { Link } from 'gatsby';
import Seo from '../components/SEO/SEO';

const errorPage = () => (
  <>
  <Layout headlines={["Seite nicht gefunden"]} className="centeredPage--TextPage">
    <h1>Auch Helden können sich verirren...</h1>
    <p>Es tut uns leid, die von dir aufgerufene Seite konnte nicht gefunden werden. Falls du Fragen hierzu hast
      kannst du uns gerne über das <Link to="/kontakt/">Kontaktformular</Link> anschreiben.
    </p>
  </Layout>
  </>
);

export default errorPage;

export function Head() { 

  return(
    <Seo 
      title = 'Hoppla, Seite nicht gefunden | Tiny House Helden'
    />
  )
}